import axios from "axios"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_SUBMIT_FEEDBACK_WEBHOOK_URL,
} from "gatsby-env-variables"

export const createFeedbackRecord = async ({
  values,
  callback,
  errorCallback,
}) => {
  try {
    let response = await axios.post(GATSBY_SUBMIT_FEEDBACK_WEBHOOK_URL, {
      ...values,
      domain: GATSBY_WEBSITE_URL,
      zendeskId: parseInt(values.zendeskId),
    })
    if (callback) callback()
    return response
  } catch (error) {
    if (errorCallback) errorCallback()
  }
}
